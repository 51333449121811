export class MetadataChangeResponder {
    constructor(session, router) {
        this.session = session;
        this.router = router;

        this.subscriptionToken = pubsub.subscribe("metadata-change", () => this.handleMetadataChange());
    }

    handleMetadataChange() {
        const metadata = utils.getMetadata();
        utils.setHeadContentTags(this.session, metadata);
        utils.publishRouteAnalytics(this.session, this.router, metadata);
    }

    dispose() {
        pubsub.unsubscribe(this.subscriptionToken);
    }
}