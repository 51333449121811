export class ShowProduct {
    constructor(firebaseClient, siteSearcher) {
        this.firebaseClient = firebaseClient;
        this.siteSearcher = siteSearcher;

        this.product = null;
        this.category = null;
        this.price_EN = null;
        this.price_TH = null;

        this.galleryImages = [];
        this.imageAlbumConfig = null;
    }

    get metadata() {
        return {
            type: "product",
            title_EN: this.product && this.product.name_EN || "",
            title_TH: this.product && this.product.name_TH || "",
            description_EN: this.product && this.product.description_EN || "",
            description_TH: this.product && this.product.description_TH || "",
            image: this.product && utils.getImageUrl(this.product, 450) || ""
        };
    }

    async activate(params) {
        let productId = params.code;
        this.product = await this.firebaseClient.getProduct(productId);
        this.category = this.product.categoryId
            ? (await this.firebaseClient.getCategory(this.product.categoryId))
            : null;

        this.galleryImages = await this.siteSearcher.getGalleryImages([productId]);
        this.imageAlbumConfig = {
            imageIds: this.galleryImages.map(i => i.id)
        };

        this.product.descriptionLines_EN = utils.splitLines(this.product.description_EN);
        this.product.descriptionLines_TH = utils.splitLines(this.product.description_TH);

        this.price_EN = utils.formatPrice("EN", this.product.price);
        this.price_TH = utils.formatPrice("TH", this.product.price);
    }
}