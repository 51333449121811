export class ImageAlbum {
    constructor(firebaseClient) {
        this.firebaseClient = firebaseClient;

        this.imageIdLookup = null;
        this.images = [];

        this.albumConfig = null;
        this.lightboxConfig = null;

        // From ref attribute
        this.lightboxContentElem = null;

        this.state = {
            lightboxViewModel: null,
            lightboxImageIndex: -1
        };
    }

    configure(config) {
        // If imageIds is not specified, list all images
        if (config && config.imageIds) {
            this.imageIdLookup = {};
            config.imageIds.forEach(id => this.imageIdLookup[id] = true);
        }
    }

    async activate() {
        let images, productLookup;
        [images, productLookup] = await Promise.all([
            this.firebaseClient.getGalleryImages(),
            this.firebaseClient.getProductLookup()
        ]);

        if (this.imageIdLookup) {
            images = images.filter(i => this.imageIdLookup[i.id]);
        }

        images.forEach(image => {
            image.products = Object.keys(image.productIds || {}).map(id => Object.assign({id: id}, productLookup[id]));
        });

        this.images = images;

        this.albumConfig = {
            images: images.map(i => ({
                id: i.id,
                hasWatermark: i.hasWatermark,
                thumbnailUrl: utils.getImageUrl(i, 150),
                alt_EN: i.description_EN,
                alt_TH: i.description_TH,
                textLines_EN: utils.splitLines(i.description_EN),
                textLines_TH: utils.splitLines(i.description_TH),
                routeCode: "show-image",
                routeArgs: {
                    code: i.id
                }
            })),
            clickHandler: imageId => this.showImage(imageId)
        };

        this.lightboxConfig = {
            registerViewModel: viewModel => this.state.lightboxViewModel = viewModel,
            nextHandler: () => this.showAtIndex(this.state.lightboxImageIndex + 1),
            previousHandler: () => this.showAtIndex(this.state.lightboxImageIndex - 1)
        };
    }

    showImage(imageId) {
        let index = this.images.findIndex(i => i.id === imageId);
        this.showAtIndex(index);
    }

    showAtIndex(index) {
        if (!this.images.length || !this.state.lightboxViewModel) {
            return;
        }

        if (index >= this.images.length) {
            index = 0;
        }

        if (index < 0) {
            index = this.images.length - 1;
        }

        this.state.lightboxImageIndex = index;

        let image = this.images[index];

        let img = this.lightboxContentElem.querySelector(`img[data-img='${image.id}']`);
        let content = this.lightboxContentElem.querySelectorAll(`[data-image-id='${image.id}']`);
        this.state.lightboxViewModel.display(img, Array.from(content), !image.hasWatermark);
    }
}