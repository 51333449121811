export class ViewManager {
    constructor(viewInfo, parentContext) {
        let element = viewInfo.element;
        let renderer = parentContext.rendererLookup[viewInfo.viewCode];

        let renderScope = utils.getScope(element);
        if (!renderScope) {
            renderScope = `${parentContext.renderScope}-${viewInfo.index.toString(32)}`;
            utils.setScope(element, renderScope);
        }

        let viewModelProvider = parentContext.viewModelProviderLookup[viewInfo.viewCode];
        if (!viewModelProvider) {
            throw new Error(`No provider for view "${viewInfo.viewCode}"`);
        }

        let viewModel = viewModelProvider(parentContext);
        let viewModelProxy = this.wrapViewModel(viewModel);

        let isCacheable = (viewModel.cacheable === undefined || viewModel.cacheable);
        let keepExistingStructure = parentContext.keepExistingStructure && isCacheable;

        // Create a new context, overwriting those properties which differ between
        // parent and child.
        this.context = Object.assign({}, parentContext, {
            viewCode: viewInfo.viewCode,
            element: element,
            viewConfig: viewInfo.config,
            renderScope: renderScope,
            viewModel: viewModelProxy,
            renderer: renderer,
            keepExistingStructure: keepExistingStructure
        });

        this.isInitializing = false;
        this.childViewManagers = [];
    }

    static getViewInfos(context) {
        let getConfig = configProperty => {
            if (!context.viewModel || !configProperty) {
                return null;
            }

            let properties = configProperty.split(".").filter(p => !!p);
            let config = context.viewModel;
            for (let property of properties) {
                config = config[property];
                if (!config) {
                    let message = `Config property ${property} missing on viewModel`;
                    log.error(message, context.viewModel, configProperty);
                    throw new Error(message);
                }
            }

            return config;
        };

        return utils.getChildViews(context.element, context.renderScope)
            .map((v, index) => ({
                index: index,
                element: v.element,
                viewCode: v.viewCode,
                config: getConfig(v.configProperty)
            }));
    }

    static async createForDocument(viewModelProviderLookup, rendererLookup, session, router, viewInitializer) {
        let element = document.body;

        let renderScope = utils.getScope(element);
        let hasExistingStructure = !!renderScope;

        if (!renderScope) {
            renderScope = "0";
            utils.setScope(document.body, renderScope);
        }

        let rootContext = {
            viewCode: "root",
            element: element,
            viewConfig: null, // no parent viewmodel to pass in configuration data
            renderer: utils.noop,
            keepExistingStructure: hasExistingStructure, // we have an existing structure: keep it
            renderScope: renderScope,
            viewModelProviderLookup: viewModelProviderLookup,
            rendererLookup: rendererLookup,
            session: session,
            router: router,
            viewInitializer: viewInitializer,
            viewModel: null // No viewmodel for the overall document
        };

        let viewInfos = this.getViewInfos(rootContext);

        let viewManagers = viewInfos.map(v => new ViewManager(v, rootContext));

        // Pass false to render to avoid forcing a re-render if the page is already rendered.
        await Promise.all(viewManagers.map(vm => vm.render()));

        return viewManagers;
    }

    wrapViewModel(viewModel) {
        let isAwaitingRender = false;
        let changeHandler = {
            set: (target, property, value) => {
                target[property] = value;

                if (!this.isInitializing && !isAwaitingRender) {
                    isAwaitingRender = true;
                    setTimeout(async () => {
                        log.debug("rendering", viewModel, property, value);
                        await this.render();
                        isAwaitingRender = false;
                    }, 0);
                }

                return true;
            }
        };

        return new Proxy(viewModel, changeHandler);
    }

    allowStructureChange() {
        if (this.context.keepExistingStructure) {
            this.context.keepExistingStructure = false;
            this.childViewManagers.forEach(vm => vm.allowStructureChange());
        }
    }

    async render() {
        // Recursively combine this and child metadata
        let oldMetadata = utils.getMetadata([this]);

        try {
            this.isInitializing = true;
            await this.context.viewInitializer.initialize(this.context);
        } finally {
            this.isInitializing = false;
        }

        this.disposeChildren();

        let childViewInfos = this.constructor.getViewInfos(this.context);

        this.childViewManagers = childViewInfos.map(v => new ViewManager(v, this.context));

        await Promise.all(this.childViewManagers.map(vm => vm.render()));

        let newMetadata = utils.getMetadata([this]);
        if (JSON.stringify(oldMetadata) !== JSON.stringify(newMetadata)) {
            pubsub.publish("metadata-change");
        }
    }

    dispose() {
        let viewModel = this.context.viewModel;
        if (viewModel.dispose && typeof viewModel.dispose === "function") {
            viewModel.dispose();
        }

        this.disposeChildren();
    }

    disposeChildren() {
        this.childViewManagers.forEach(vm => vm.dispose());
    }
}