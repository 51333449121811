import fetchival from "fetchival";

export class RestClient {
    constructor(apiRoot) {
        this.apiRoot = apiRoot.replace(/\/$/, "");
    }

    async get(resourceName) {
        log.debug("REST CLIENT REQUEST", resourceName);

        let resource = fetchival(`${this.apiRoot}/${resourceName}`, { mode: "cors" });
        return await resource.get();
    }
}