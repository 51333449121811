export class NavBar {
    constructor(session, router, appTitle_EN, appTitle_TH) {
        this.session = session;
        this.router = router;
        this.navigableRoutes = ["about-us", "contact-us", "list-products", "gallery"]
            .map(routeCode => router.routeInfoLookup[routeCode].route);
        this.appTitle_EN = appTitle_EN;
        this.appTitle_TH = appTitle_TH;

        // From ref attributes
        this.menuToggle = null;
        this.navigationMenu = null;
        this.colorDisplayToggle = null;
        this.colorDisplay = null;

        this.subscriptionToken = null;
    }

    rendered() {
        this.subscriptionToken = pubsub.subscribe("route-change", () => this.setActiveItem());
        this.setActiveItem();

        this.menuToggle.addEventListener("click", e => this.toggleNavigationMenu(e));

        if (this.colorDisplayToggle) {
            this.colorDisplayToggle.addEventListener("click", e => this.toggleColorDisplay(e));
        }
    }

    setActiveItem() {
        let activeItem = this.navigationMenu.querySelector("li.active-nav-item");
        if (activeItem) {
            activeItem.classList.remove("active-nav-item");
        }

        if (this.router.activeRouteCode) {
            activeItem = this.navigationMenu.querySelector(`li[data-route-code='${this.router.activeRouteCode}']`);
            if (activeItem) {
                activeItem.classList.add("active-nav-item");
            }
        }
    }

    toggleNavigationMenu(e) {
        e.preventDefault();
        e.stopPropagation();
        this.navigationMenu.classList.toggle("small-screen-hidden");
    }

    toggleColorDisplay(e) {
        e.preventDefault();
        e.stopPropagation();
        this.colorDisplay.classList.toggle("hidden");
    }

    dispose() {
        pubsub.unsubscribe(this.subscriptionToken);
    }
}