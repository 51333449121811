export class ViewInitializer {
    async initialize(context) {
        // Pass any configuration data to the viewModel first (this is passed
        // from the parent view via the data-config attribute).
        let viewModel = context.viewModel;
        if (viewModel.configure && typeof viewModel.configure === "function") {
            viewModel.configure(context.viewConfig);
        }

        // Call activate on the viewmodel if not already called
        if (viewModel.activate && typeof viewModel.activate === "function") {
            let params = context.router.activeRouteArgs;
            if (!params) {
                throw new Error("Router activeRouteArgs not set");
            }

            await Promise.resolve(viewModel.activate(params));
        }

        if (!context.keepExistingStructure) {
            // Render this element's contents.
            context.element.innerHTML = context.renderer(viewModel);
        }

        // Set the URLs of any anchor elements according to the routes they represent
        utils.setUrls(context.session, context.router, context.element);
        utils.setLangElems(context.session, context.element);
        utils.setImgAltText(context.session, context.element);

        // Set "ref" element values on the view model
        utils.getChildRefValues(context.element, context.renderScope).forEach(v => {
            try {
                viewModel[v.property] = v.element;
            } catch (err) {
                let message = `Failed to set "ref" property ${v.property}`;
                log.error(err, viewModel, v.element, message);
                throw new Error(message);
            }
        });

        utils.loadSocialPlugins(context.element);

        // Let the view model know the view has been rendered
        if (viewModel.rendered && typeof viewModel.rendered === "function") {
            viewModel.rendered();
        }
    }
}