export class ContactUs {
    constructor(businessInfo) {
        this.businessInfo = businessInfo;

        this.subscriptionToken = null;
        this.map = null;
        this.marker = null;

        // From ref attribute
        this.mapElem = null;
    }

    get metadata() {
        return {
            type: "article",
            title_EN: "Contact Us",
            title_TH: "ติดต่อเรา",
            description_EN: "How to get in touch with Fabric Buttons",
            description_TH: "ช่องทางติดต่อเรา",
            image: `${utils.publicOrigin}/${this.businessInfo.imagePath}`
        };
    }

    rendered() {
        if (google) {
            this.initMap();
        } else {
            this.subscriptionToken = pubsub.subscribe("maps-api-ready", () => this.initMap());
        }
    }

    initMap() {
        let position = {
            lat: this.businessInfo.latitude,
            lng: this.businessInfo.longitude
        };

        this.map = new google.maps.Map(this.mapElem, {
            zoom: 16,
            center: position
        });

        this.marker = new google.maps.Marker({
            position: position,
            map: this.map
        });
    }

    dispose() {
        if (this.subscriptionToken) {
            pubsub.unsubscribe(this.subscriptionToken);
        }
    }
}