export class RouteChangeResponder {
    constructor(session, router) {
        this.session = session;
        this.router = router;

        this.subscriptionToken = pubsub.subscribe("route-change", () => this.handleRouteChange());
    }

    handleRouteChange() {
        utils.setHeadRouteTags(this.session, this.router);
    }

    dispose() {
        pubsub.unsubscribe(this.subscriptionToken);
    }
}