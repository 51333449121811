export class FirebaseClient {
    constructor(restClient, activeProject) {
        this.restClient = restClient;
        this.activeProject = activeProject;
    }

    async getCategoryLookup() {
        let categoryLookup = await this.restClient.get(`categories/${this.activeProject}.json`);
        return categoryLookup || {};
    }

    async getCategories() {
        let categoryLookup = await this.getCategoryLookup();
        return Object.keys(categoryLookup)
            .map(id => Object.assign({id: id}, categoryLookup[id]))
            .sort((a, b) => a.position - b.position);
    }

    async getCategory(categoryId) {
        return await this.restClient.get(`categories/${this.activeProject}/${categoryId}.json`);
    }

    async getProductLookup() {
        let productLookup = await this.restClient.get(`products/${this.activeProject}.json`);
        return productLookup || {};
    }

    async getProducts() {
        let productLookup = await this.getProductLookup();
        return Object.keys(productLookup)
            .map(id => Object.assign({id: id}, productLookup[id]))
            .sort((a, b) => b.time - a.time);
    }

    async getProduct(productId) {
        return await this.restClient.get(`products/${this.activeProject}/${productId}.json`);
    }

    async getTagLookup() {
        let tagLookup = await this.restClient.get(`tags/${this.activeProject}.json`);
        return tagLookup || {};
    }

    async getTags() {
        let tagLookup = await this.getTagLookup();
        return Object.keys(tagLookup)
            .map(tag => Object.assign({tag: tag}, Object.keys(tagLookup[tag])))
            .sort((a, b) => a.tag.localeCompare(b.tag));
    }

    async getGalleryImageLookup() {
        let imageLookup = await this.restClient.get(`gallery/${this.activeProject}.json`);
        return imageLookup || {};
    }

    async getGalleryImages() {
        let imageLookup = await this.getGalleryImageLookup();
        return Object.keys(imageLookup)
            .map(id => Object.assign({id: id}, imageLookup[id]))
            .sort((a, b) => b.time - a.time);
    }

    async getGalleryImage(imageId) {
        return await this.restClient.get(`gallery/${this.activeProject}/${imageId}.json`);
    }
}