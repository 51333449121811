export class LangaugeChangeResponder {
    constructor(session, router) {
        this.session = session;
        this.router = router;

        this.subscriptionToken = pubsub.subscribe("language-change", () => this.handleLanguageChange());
    }

    handleLanguageChange() {
        // Happens in response to a subset of route changes
        utils.setUrls(this.session, this.router, document.body);
        utils.setLangElems(this.session, document.body);
        utils.setImgAltText(this.session, document.body);

        utils.setHeadLanguageTags(this.session);

        // Some metadata contains localizable strings so refresh these tags too
        let metadata = utils.getMetadata();
        utils.setHeadContentTags(this.session, metadata);
        utils.publishRouteAnalytics(this.session, this.router, metadata);
    }

    dispose() {
        pubsub.unsubscribe(this.subscriptionToken);
    }
}