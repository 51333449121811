const DefaultLanguage = "TH";

export class Session {
    constructor() {
        this.refreshLanguage();
        this.subscriptionToken = pubsub.subscribe("route-change", () => this.refreshLanguage());
    }

    refreshLanguage() {
        let language = utils.getLanguage() || DefaultLanguage;
        if (language !== this.selectedLanguage) {
            this.selectedLanguage = language;
            pubsub.publish("language-change");
        }
    }

    dispose() {
        pubsub.unsubscribe(this.subscriptionToken);
    }
}