export class ListProducts {
    constructor(firebaseClient) {
        this.firebaseClient = firebaseClient;
    }

    get metadata() {
        return {
            type: "product.group",
            title_EN: "Our Products",
            title_TH: "สินค้า",
            description_EN: "A list of the products we sell",
            description_TH: "สินค้าและบริการของเรา",
            image: `${utils.publicOrigin}/image/fb-facebook-image.jpg`
        };
    }
}