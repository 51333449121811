export class LanguageSelector {
    constructor(session, router) {
        this.session = session;
        this.router = router;

        this.languages = [
            {
                value: "EN",
                text: "English"
            },
            {
                value: "TH",
                text: "ไทย"
            }
        ];

        // From ref attribute
        this.containerElem = null;

        this.subscriptionToken = null;
    }

    rendered() {
        // The ref elements should be available by now
        this.subscriptionToken = pubsub.subscribe("route-change", () => this.refreshView());
        this.refreshView();
    }

    refreshView() {
        let links = Array.from(this.containerElem.querySelectorAll("a"));
        links.forEach(link => {
            let language = link.getAttribute("data-language");

            // Overwrite the existing route args with the specific language for this link
            let routeArgs = Object.assign({}, this.router.activeRouteArgs, {
                lang: language.toLowerCase()
            });

            link.setAttribute("data-route", this.router.activeRouteCode);
            link.setAttribute("data-route-args", JSON.stringify(routeArgs));

            if (language === this.session.selectedLanguage) {
                link.classList.add("selected");
            } else {
                link.classList.remove("selected");
            }
        });

        // Having set the data-route and data-route-args attributes on each of the links
        // we now go through and make sure the href values are updated accordingly.
        utils.setUrls(this.session, this.router, this.containerElem);
    }

    dispose() {
        pubsub.unsubscribe(this.subscriptionToken);
    }
}