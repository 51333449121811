const DebounceTimeout = 500;

export class SearchBar {
    constructor(session, siteSearcher) {
        this.session = session;
        this.siteSearcher = siteSearcher;

        // from ref attributes
        this.inputElem = null;
        this.searchButtonElem = null;

        this.state = {
            resultChangeListener: null
        };

        this.subscriptionToken = null;
    }

    activate() {
        this.subscriptionToken = pubsub.subscribe("language-change", () => this.setPlaceholder());
    }

    rendered() {
        this.setPlaceholder();

        this.searchButtonElem.onclick = e => {
            e.preventDefault();

            // No need to debounce: we want the click to be handled immediately.
            this.startPerformSearch();
        };

        this.inputElem.onkeydown = e => this.handleInputKeyDown(e || window.event);

        let debouncedPerformSearch = utils.debounce(
            () => this.startPerformSearch(),
            DebounceTimeout);

        this.inputElem.oninput = debouncedPerformSearch;
    }

    setPlaceholder() {
        this.inputElem.placeholder = utils.getLocalizedValue(this.session, {
            text_EN: "Search",
            text_TH: "ค้นหา"
        }, "text");
    }

    handleInputKeyDown(e) {
        // Recommended but not widely supported
        // https://developer.mozilla.org/en-US/docs/Web/API/KeyboardEvent/key/Key_Values

        // Deprecated but widely supported
        // https://developer.mozilla.org/en-US/docs/Web/API/KeyboardEvent/keyCode
        let isEnter = "key" in e
            ? e.key === "Enter"
            : e.keyCode === 13;

        if (isEnter) {
            this.startPerformSearch();
        }
    }

    startPerformSearch() {
        // Fire-and-forget version of performSearch
        this.performSearch().catch(log.error);
    }

    async performSearch() {
        let text = this.inputElem.value;
        let products = await this.siteSearcher.searchProducts(text);
        let galleryImages = await this.siteSearcher.getGalleryImages(products.map(p => p.id));

        if (this.state.resultChangeListener) {
            this.state.resultChangeListener(products, galleryImages);
        }
    }

    setResultChangeListener(listener) {
        this.state.resultChangeListener = listener;
    }

    dispose() {
        pubsub.unsubscribe(this.subscriptionToken);
    }
}