// Cannot extend Array, see: https://github.com/babel/babel/issues/1424
export class SearchResults {
    constructor() {
        this.resultGroups = [];
        this.addResultGroup();
    }

    addResultGroup() {
        if (!this.currentResultGroup || !this.currentResultGroup.isEmpty()) {
            this.currentResultGroup = new SearchResultGroup();
            this.resultGroups.push(this.currentResultGroup);
        }
    }

    addMatch(resultId) {
        if (!this.currentResultGroup) {
            this.addResultGroup();
        }

        for (let resultGroup of this.resultGroups) {
            if (resultGroup[resultId]) {
                // Already in results, don't add it.
                return;
            }
        }

        this.currentResultGroup[resultId] = true;
    }

    getCount() {
        return this.resultGroups.reduce((count, group) => count + Object.keys(group).length, 0);
    }

    getSortedEntities(entityRetriever, entityComparer, maxResultCount) {
        let results = [];
        for (let resultGroup of this.resultGroups) {
            let groupEntities = Object.keys(resultGroup)
                .map(id => entityRetriever(id))
                .sort(entityComparer);

            for (let entity of groupEntities) {
                if (results.length >= maxResultCount) {
                    return results;
                }

                results.push(entity);
            }
        }

        return results;
    }
}

class SearchResultGroup {
    isEmpty() {
        for (let key in this) {
            return false;
        }

        return true;
    }
}