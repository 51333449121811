export class ShowImage {
    constructor(firebaseClient, session) {
        this.firebaseClient = firebaseClient;
        this.session = session;

        this.image = null;

        this.productIds = [];
        this.productCardsConfig = null;
    }

    get metadata() {
        return {
            type: "article",
            title_EN: this.image && this.image.description_EN || "",
            title_TH: this.image && this.image.description_TH || "",
            description_EN: "",
            description_TH: "",
            image: this.image && utils.getImageUrl(this.image, 450) || ""
        };
    }

    async activate(params) {
        let imageId = params.code;

        this.image = await this.firebaseClient.getGalleryImage(imageId);
        this.image.descriptionLines_EN = utils.splitLines(this.image.description_EN);
        this.image.descriptionLines_TH = utils.splitLines(this.image.description_TH);
        this.productIds = Object.keys(this.image.productIds || {});

        this.productCardsConfig = {
            productIds: this.productIds
        };
    }
}