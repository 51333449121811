// https://gist.github.com/fatihacet/1290216
export class PubSub {
    constructor() {
        this.topics = {};
        this.subUid = -1;
    }

    subscribe(topic, func) {
        if (!this.topics[topic]) {
            this.topics[topic] = [];
        }

        let token = (++this.subUid).toString();
        this.topics[topic].push({
            token: token,
            func: func
        });

        return token;
    }

    publish(topic, ...args) {
        if (!this.topics[topic]) {
            return false;
        }

        setTimeout(() => {
            let subscribers = this.topics[topic];
            let len = subscribers ? subscribers.length : 0;

            while (len--) {
                subscribers[len].func(...args);
            }
        }, 0);

        return true;
    }

    unsubscribe(token) {
        for (let topic in this.topics) {
            let subscribers = this.topics[topic];
            if (subscribers) {
                for (let i = 0; i < subscribers.length; i++) {
                    if (subscribers[i].token === token) {
                        subscribers.splice(i, 1);
                        return token;
                    }
                }
            }
        }

        return false;
    }
}