export class PageContainer {
    constructor(router) {
        this.router = router;

        this.routeCode = null;
        this.routeArgsString = null;

        this.subscriptionToken = null;
    }

    activate() {
        this.subscriptionToken = pubsub.subscribe("route-change", () => this.refreshView());
    }

    refreshView() {
        let activeRouteArgsString = JSON.stringify(this.router.activeRouteArgs);
        if (this.routeCode !== this.router.activeRouteCode ||
            this.routeArgsString !== activeRouteArgsString) {
            this.routeCode = this.router.activeRouteCode;
            this.routeArgsString = activeRouteArgsString;
        }
    }

    dispose() {
        pubsub.unsubscribe(this.subscriptionToken);
    }
}