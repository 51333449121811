const DefaultCacheMinutes = 30;

export class CachingRestClient {
    constructor(restClient, cacheMinutes) {
        this._restClient = restClient;
        this._cacheMinutes = cacheMinutes || DefaultCacheMinutes;
        this._cache = {};
    }

    async get(resourceName) {
        log.debug("CACHE REQUEST", resourceName);

        let cachedResource = this._cache[resourceName];
        if (!cachedResource || cachedResource.expiry < new Date()) {
            log.debug("CACHE MISS", resourceName);
            cachedResource = {
                expiry: (new Date()).getTime() + this._cacheMinutes * 60000,
                valuePromise: getResourceValue(this._restClient, resourceName)
            };

            this._cache[resourceName] = cachedResource;
        } else {
            log.debug("CACHE HIT", resourceName);
        }

        log.debug("AWAIT", resourceName);
        let value = await cachedResource.valuePromise;

        log.debug("RETRIEVE DONE", resourceName);
        return JSON.parse(value);
    }
}

async function getResourceValue(restClient, resourceName) {
    log.debug("RETRIEVE START", resourceName);

    let resource = await restClient.get(resourceName);
    return JSON.stringify(resource);
}