export class Lightbox {
    constructor() {
        // From ref attributes
        this.lightboxElem = null;
        this.closeButton = null;
        this.prevButton = null;
        this.nextButton = null;
        this.imgContainer = null;
        this.contentContainer = null;

        this.state = {
            previousHandler: null,
            nextHandler: null
        };
    }

    configure(config) {
        if (config.registerViewModel) {
            config.registerViewModel(this);
        }

        this.state.previousHandler = config.previousHandler;
        this.state.nextHandler = config.nextHandler;
    }

    rendered() {
        utils.handleClick(this.closeButton, () => this.close());
        utils.handleClick(this.prevButton, () => this.requestPrevious());
        utils.handleClick(this.nextButton, () => this.requestNext());
    }

    display(imgElem, contentElems, displayWatermark) {
        // Clear the containers
        removeChildren(this.imgContainer);
        removeChildren(this.contentContainer);

        // Clone all supplied elements
        imgElem = imgElem.cloneNode(false); // not deep
        contentElems = contentElems.map(elem => elem.cloneNode(true)); // deep

        // Add supplied elements to their respective containers
        this.imgContainer.appendChild(imgElem);
        contentElems.forEach(elem => {
            this.contentContainer.appendChild(elem);
        });

        // Display/clear watermark
        this.imgContainer.classList.toggle("watermark", displayWatermark);

        // Display the lightbox
        this.lightboxElem.style.display = "block";
    }

    requestNext() {
        if (this.state.nextHandler) {
            this.state.nextHandler();
        }
    }

    requestPrevious() {
        if (this.state.nextHandler) {
            this.state.previousHandler();
        }
    }

    close() {
        this.lightboxElem.style.display = "none";
    }
}

function removeChildren(parentNode) {
    while (parentNode.hasChildNodes()) {
        parentNode.removeChild(parentNode.lastChild);
    }
}