export class PageNotFound {
    constructor(siteSearcher) {
        this.siteSearcher = siteSearcher;

        this.productCardsConfig = null;
        this.imageAlbumConfig = null;
        this.links = [];
        this.hasResults = false;
    }

    get cacheable() {
        return false;
    }

    async activate(params) {
        this.links = [];
        let urlParts = (params.anything || "").split("/");

        if (urlParts.some(p => /(home|index)/i.test(p))) {
            this.links.push({
                text_EN: "Home",
                text_TH: "หน้าแรก",
                routeCode: "home"
            });
        }

        if (urlParts.some(p => /about/i.test(p))) {
            this.links.push({
                text_EN: "About Us",
                text_TH: "เกี่ยวกับเรา",
                routeCode: "about-us"
            });
        }

        if (urlParts.some(p => /(product|shop)/i.test(p))) {
            this.links.push({
                text_EN: "Our Products",
                text_TH: "สินค้า",
                routeCode: "list-products"
            });
        }

        if (urlParts.some(p => /gallery/i.test(p))) {
            this.links.push({
                text_EN: "Gallery",
                text_TH: "ตัวอย่างผลงาน",
                routeCode: "gallery"
            });
        }

        let products = await this.siteSearcher.searchProducts(urlParts.join(" "));
        let productIds = products.map(p => p.id);

        this.productCardsConfig = {
            productIds: productIds
        };

        let galleryImages = await this.siteSearcher.getGalleryImages(productIds);
        let galleryImageIds = galleryImages.map(i => i.id);

        this.imageAlbumConfig = {
            imageIds: galleryImageIds
        };

        this.hasResults =
            this.links.length ||
            this.productCardsConfig.productIds.length ||
            this.imageAlbumConfig.imageIds.length;
    }
}