const ImageHeight = 300;
const ProductChangeEveryMs = 2000;
const MaxGalleryImages = 12;

export class Home {
    constructor(firebaseClient, session, businessInfo) {
        this.firebaseClient = firebaseClient;
        this.session = session;
        this.businessInfo = businessInfo;

        this.categories = [];

        this.categoryCarouselConfigLookup = {};
        this.imageAlbumConfig = null;

        this.subscriptionToken = null;
        this.map = null;
        this.marker = null;

        // From ref attribute
        this.mapElem = null;
    }

    get metadata() {
        return {
            type: "business.business",
            title_EN: this.businessInfo.name,
            title_TH: this.businessInfo.name,
            description_EN: this.businessInfo.description_EN,
            description_TH: this.businessInfo.description_TH,
            image: `${utils.publicOrigin}/${this.businessInfo.imagePath}`,
            otherProperties: {
                "business:contact_data:street_address": {
                    value_EN: this.businessInfo.streetAddress_EN,
                    value_TH: this.businessInfo.streetAddress_TH
                },
                "business:contact_data:locality": {
                    value_EN: this.businessInfo.locality_EN,
                    value_TH: this.businessInfo.locality_TH
                },
                "business:contact_data:postal_code": this.businessInfo.postalCode,
                "business:contact_data:country_name": {
                    value_EN: this.businessInfo.country_EN,
                    value_TH: this.businessInfo.country_TH
                },
                "business:contact_data:phone_number": this.businessInfo.phoneNumber,
                "business:contact_data:fax_number": this.businessInfo.faxNumber,
                "business:contact_data:email": this.businessInfo.email,
                "business:contact_data:website": {
                    value_EN: `${utils.publicOrigin}/en`,
                    value_TH: `${utils.publicOrigin}/th`
                },
                "place:location:latitude": this.businessInfo.latitude,
                "place:location:longitude": this.businessInfo.longitude
            }
        };
    }

    async activate() {
        let products, galleryImages;
        [products, galleryImages, this.categories] = await Promise.all([
            this.firebaseClient.getProducts(),
            this.firebaseClient.getGalleryImages(),
            this.firebaseClient.getCategories()
        ]);

        let usedCategoryIdLookup = {};
        products.forEach(p => {
            usedCategoryIdLookup[p.categoryId] = true;
        });

        let usedCategories = this.categories.filter(c => usedCategoryIdLookup[c.id]);

        let singleCategoryChangeInterval = usedCategories.length * ProductChangeEveryMs;

        products.forEach(p => {
            let categoryIndex = usedCategories.map(c => c.id).indexOf(p.categoryId);

            let carouselConfig =
                this.categoryCarouselConfigLookup[p.categoryId] =
                this.categoryCarouselConfigLookup[p.categoryId] || {
                    imageHeight: ImageHeight,
                    items: [],
                    changeAfterMs: singleCategoryChangeInterval + (categoryIndex * ProductChangeEveryMs),
                    changeEveryMs: singleCategoryChangeInterval
                };

            carouselConfig.items.push({
                routeCode: "show-product",
                routeArgs: {code: p.id},
                hasWatermark: p.hasWatermark || false,
                imageUrl: utils.getImageUrl(p, 450),
                name_EN: p.name_EN,
                name_TH: p.name_TH,
                descriptionLines_EN: utils.splitLines(p.description_EN),
                descriptionLines_TH: utils.splitLines(p.description_TH)
            });
        });

        // Only include categories which have associated products
        this.categories = this.categories.filter(c => this.categoryCarouselConfigLookup[c.id]);

        this.imageAlbumConfig = {
            imageIds: galleryImages
                .filter((image, index) => index < MaxGalleryImages)
                .map(image => image.id)
        };
    }

    rendered() {
        if (google) {
            this.initMap();
        } else {
            this.subscriptionToken = pubsub.subscribe("maps-api-ready", () => this.initMap());
        }
    }

    initMap() {
        let position = {
            lat: this.businessInfo.latitude,
            lng: this.businessInfo.longitude
        };

        this.map = new google.maps.Map(this.mapElem, {
            zoom: 16,
            center: position
        });

        this.marker = new google.maps.Marker({
            position: position,
            map: this.map
        });
    }

    dispose() {
        if (this.subscriptionToken) {
            pubsub.unsubscribe(this.subscriptionToken);
        }
    }
}