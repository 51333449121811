export class ProductCards {
    constructor(firebaseClient) {
        this.firebaseClient = firebaseClient;

        this.productIdLookup = null;
        this.products = [];
    }

    configure(config) {
        // If productIds is not specified, list all products
        if (config && config.productIds) {
            this.productIdLookup = {};
            config.productIds.forEach(id => this.productIdLookup[id] = true);
        }
    }

    async activate() {
        let products, categoryLookup;
        [products, categoryLookup] = await Promise.all([
            this.firebaseClient.getProducts(),
            this.firebaseClient.getCategoryLookup()
        ]);

        if (this.productIdLookup) {
            products = products.filter(p => this.productIdLookup[p.id]);
        }

        products.forEach(product => {
            product.category = product.categoryId ? categoryLookup[product.categoryId] : null;
            product.descriptionLines_EN = utils.splitLines(product.description_EN);
            product.descriptionLines_TH = utils.splitLines(product.description_TH);
            product.price_EN = utils.formatPrice("EN", product.price);
            product.price_TH = utils.formatPrice("TH", product.price);
        });

        this.products = products;
    }
}