export let config = {
    appTitle_EN: "Fabric Buttons",
    appTitle_TH: "รุ่งมณี",
    firebaseDbUrl: "https://fabric-buttons.firebaseio.com",
    prerenderOrigins: [
        "http://localhost:8080",
        "https://buttons-builder.herokuapp.com"
    ],
    publicOrigin: "https://www.fabric-buttons.com",
    googleAnalyticsCode: "UA-23425702-1",
    logLevel: BUILD_MODE === "release" ? "warn" : "info",
    activeProject: ACTIVE_PROJ,
    cacheMinutes: 15,
    businessInfo: {
        name: "Fabric Buttons | รุ่งมณี",
        description_EN: "A manufacturer/wholesaler/retailer of tailor-made, hand-crafted fabric-covered buttons, and materials and kits for making them. We supply buttons, zips, bias tape, belt buckles, etc. to garment and furniture companies, or made-to-order buttons with your own fabric",
        description_TH: "ผู้ผลิต และจำหน่าย ขายส่ง-ขายปลีก อุปกรณ์เย็บปักถักร้อยทุกชนิด (ผ้ากุ๊น เข็มกลัด ด้าย ริบบิ้น) อุปกรณ์การทำกระดุมปั๊มผ้าและเข็มขัดผ้า (เครื่องปั๊มกระดุม บล๊อก อะไหล่) รับสั่งทำกระดุมจีน (กระดุมตะกร้อ) กระดุมปั๊มผ้า เข็มขัดหุ้มผ้า กระดุมตาไก่ กระดุมดอกไม้ หน้าร้านอยู่สำเพ็ง พร้อมให้คำแนะนำเกี่ยวกับการนำไปใช้ตกแต่งเสื้อผ้า เครื่องประดับ ต่างหู หรือกิ๊บติดผม เป็นต้น",
        imagePath: "image/fb-facebook-image.jpg",
        latitude: 13.743413,
        longitude: 100.504174,
        streetAddress_EN: "40 Soi Leanlit Sampeng Vanich 1 Road",
        streetAddress_TH: "40 ซอยเลื่อนฤทธื์ ถนนสำเพ็ง วานิช 1",
        locality_EN: "Bangkok",
        locality_TH: "กรุงเทพมหานคร",
        postalCode: "10100",
        country_EN: "Thailand",
        country_TH: "ไทย",
        phoneNumber: "(+66) 02-2228726",
        faxNumber: "(+66) 02-4724092",
        email: "sales@fabric-buttons.com"
    }
};