const DefaultImageHeight = 150;

export class Carousel {
    constructor() {
        this.imageHeight = DefaultImageHeight;
        this.items = [];
        this.changeAfterMs = null;
        this.changeEveryMs = null;

        // From ref attributes
        this.containerElem = null;
        this.prevButton = null;
        this.nextButton = null;
        this.listElem = null;

        // Queried after render
        this.listItems = [];

        this.state = {
            currentItem: null,
            counter: 0,
            interval: null
        };
    }

    configure(config) {
        this.imageHeight = config.imageHeight || DefaultImageHeight;
        this.items = config.items;
        this.changeAfterMs = config.changeAfterMs || config.changeEveryMs;
        this.changeEveryMs = config.changeEveryMs;
    }

    rendered() {
        this.listItems = this.listElem.querySelectorAll("li");

        this.containerElem.classList.add("active");

        this.nextButton.addEventListener("click", () => this.navigate(1));
        this.prevButton.addEventListener("click", () => this.navigate(-1));

        if (this.changeAfterMs) {
            window.setTimeout(() => {
                this.navigate(1);

                if (this.changeEveryMs) {
                    this.state.interval = window.setInterval(() => {
                        this.navigate(1);
                    }, this.changeEveryMs);
                }
            }, this.changeAfterMs || 0);
        }

        this.navigate(0);
    }

    navigate(direction) {
        if (!this.listItems.length) {
            return;
        }

        if (this.state.currentItem) {
            this.state.currentItem.classList.remove("current");
        }

        this.state.counter += direction;

        // Cycle to keep counter in range
        if (this.state.counter < 0) {
            this.state.counter = this.listItems.length - 1;
        }

        if (this.state.counter >= this.listItems.length) {
            this.state.counter = 0;
        }

        this.state.currentItem = this.listItems[this.state.counter];
        this.state.currentItem.classList.add("current");
    }

    dispose() {
        if (this.state.interval) {
            window.clearInterval(this.state.interval);
        }
    }
}