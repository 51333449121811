let _hostname = (location.host || "localhost").split(":")[0].toLowerCase();
let _isLocalhost = _hostname === "localhost" || _hostname === "127.0.0.1";
let _isPrerendering = null;
let _publicOrigin = null;
let _googleAnalyticsCode = null;
let _lastViewedPagePath = null;

export class Utils {
    static preventImageContextMenu() {
        // http://stackoverflow.com/questions/737022/how-do-i-disable-right-click-on-my-web-page/21497480#21497480
        document.addEventListener("contextmenu", function(e){
            // elem.nodeName can be relied upon to be uppercase:
            // http://ejohn.org/blog/nodename-case-sensitivity/
            if (e.target.nodeName === "IMG") {
                e.preventDefault();
            }
        }, false);
    }

    static getBaseHref() {
        // Can't just use `element.href` here, because that returns the
        // absolute URL (including origin).
        return document.getElementsByTagName("base")[0].getAttribute("href");
    }

    static getLanguage() {
        let baseHref = this.getBaseHref();
        let regex = new RegExp(`^${baseHref}(en|th)(?:$|/)`, "i");
        let matches = window.location.pathname.match(regex);
        if (matches && matches.length === 2) {
            return matches[1].toUpperCase();
        }

        return null;
    }

    static setBrowserEnv(prerenderOrigins, publicOrigin, googleAnalyticsCode) {
        _isPrerendering = prerenderOrigins.some(o => o === location.origin.toLowerCase());
        _publicOrigin = publicOrigin;
        _googleAnalyticsCode = googleAnalyticsCode;
    }

    static get hostname() {
        return _hostname;
    }

    static get isLocalhost() {
        return _isLocalhost;
    }

    static get isPrerendering() {
        if (_isPrerendering === null) {
            throw new Error("Attempt to get isPrerendering property before setBrowserEnv has been called.");
        }

        return _isPrerendering;
    }

    static get publicOrigin() {
        if (_publicOrigin === null) {
            throw new Error("Attempt to get publicOrigin property before setBrowserEnv has been called.");
        }

        return _publicOrigin;
    }

    static getImageUrl(obj, size) {
        if (!obj.imageUrls) {
            throw new Error("No 'imageUrls' property on object", obj);
        }

        let storageUrl = obj.imageUrls[size.toString()];
        if (!storageUrl) {
            throw new Error(`No image URL with size ${size}`, obj.imageUrls);
        }

        // Match the filename:
        // - Anything that's not a forward slash
        // - Followed by a '.'
        // - Followed by zero or more word characters
        // - Followed (as a positive lookahead, not matched)
        //   - by a '?' and any characters (optionally)
        //   - then by the end of input
        let imageFilenameMatch = storageUrl.match(/[^\/]+\.\w+(?=(\?.+)?$)/);
        if (!imageFilenameMatch) {
            log.warn(`Unable to extract filename from ${storageUrl}`);
            return storageUrl;
        }

        // E.g. images%2Fproduction%2Fgoogle-oauth2%7C106061563573452395430%2F-KeXfI9P_78a0dyUyR0i%2F-KeXfI9P_78a0dyUyR0i_450.jpg
        let encodedFilename = imageFilenameMatch[0];

        // The decoded filename is itself a path, to a firebase storage location.
        // The decoded path contains 5 components:
        // - "images", <projectKey>, <uid>, <entity-id>, <entity-id>_<size>.<extension>
        let storagePathParts = decodeURIComponent(encodedFilename).split("/");
        if (storagePathParts.length !== 5) {
            log.info(`Not processing ${storageUrl} as it does not appear to be a standard storage location path`);
            return storageUrl;
        }

        let entityId = storagePathParts[3];

        if (!imageFilenameLookup || !imageFilenameLookup[entityId]) {
            log.info(`Not processing ${storageUrl} as ${entityId} does not exist in the lookup`);
            return storageUrl;
        }

        let baseHref = this.getBaseHref().replace(/\/$/, "");
        let relativeImagePath = imageFilenameLookup[entityId][size.toString()].replace(/^\//, "");
        return `${baseHref}/${relativeImagePath}`;
    }

    static getLocale(session) {
        switch (session.selectedLanguage) {
        case "EN":
            return "en_US";
        case "TH": {
            return "th_TH";
        }
        default:
            throw new Error(`Unexpected language ${session.selectedLanguage}`);
        }
    }

    static getLocalizedText(session, text_EN, text_TH) {
        return this.getLocalizedValue(session, {
            text_EN: text_EN,
            text_TH: text_TH
        }, "text");
    }

    static getLocalizedValue(session, object, property, throwIfUndefined = true) {
        switch (session.selectedLanguage) {
        case "EN":
        case "TH": {
            let propertyName = `${property}_${session.selectedLanguage}`;
            if (!object.hasOwnProperty(propertyName)) {
                if (throwIfUndefined) {
                    throw new Error(`Object has no property ${propertyName}`);
                } else {
                    return null;
                }
            }

            return object[propertyName];
        }
        default:
            throw new Error(`Unexpected language ${session.selectedLanguage}`);
        }
    }

    static formatPrice(language, price) {
        if (!price) {
            return null;
        }

        switch (language) {
        case "EN": return `THB ${price}`;
        case "TH": return `${price} บาท`;
        default:
            throw new Error(`Unexpected language ${language}`);
        }
    }

    static splitLines(text) {
        return (text || "")
            .split(/[\r\n]+/)
            .map(str => str.trim())
            .filter(str => !!str);
    }

    // https://davidwalsh.name/javascript-debounce-function
    static debounce(func, wait, immediate) {
        var timeout;
        return function() {
            var context = this, args = arguments;
            var later = function() {
                timeout = null;
                if (!immediate) func.apply(context, args);
            };
            var callNow = immediate && !timeout;
            clearTimeout(timeout);
            timeout = setTimeout(later, wait);
            if (callNow) func.apply(context, args);
        };
    }

    static handleClick(elem, handler) {
        elem.onclick = e => {
            e = e || window.event;
            e.preventDefault();
            e.stopPropagation();

            handler(e);
        };
    }

    static handleClicks(handler) {
        // http://stackoverflow.com/a/12552017
        this.handleClick(document.body, e => {
            log.debug("handling click event", e);

            let elem = findParent("a", e.target || e.srcElement);
            if (elem) {
                let routeCode = elem.getAttribute("data-route");
                if (routeCode) {
                    let routeArgs = JSON.parse(elem.getAttribute("data-route-args") || "{}");
                    handler(routeCode, routeArgs);
                }
            }
        });
    }

    static setUrls(session, router, elem) {
        let links = Array.from(elem.querySelectorAll("a[data-route]"));
        links.forEach(link => {
            let routeCode = link.getAttribute("data-route");

            let routeArgsJson = link.getAttribute("data-route-args") || "{}";
            let routeArgs;
            try {
                routeArgs = JSON.parse(routeArgsJson);
            } catch (err) {
                log.error(err, `Error parsing route args "${routeArgsJson}"`);
                throw err;
            }

            link.href = this.getRoutePath(session, router, routeCode, routeArgs);
        });
    }

    static setLangElems(session, parentElem) {
        let currentLang = session.selectedLanguage.toLowerCase();
        let elems = Array.from(parentElem.querySelectorAll("[lang]"));
        elems.forEach(elem => {
            elem.style.display = elem.lang === currentLang ? "" : "none";
        });
    }

    static setImgAltText(session, parentElem) {
        let currentLang = session.selectedLanguage.toLowerCase();
        let imgElems = Array.from(parentElem.querySelectorAll("img"));
        imgElems.forEach(img => {
            img.alt = img.getAttribute(`data-alt-${currentLang}`);
        });
    }

    static getRoutePath(session, router, routeCode, routeArgs) {
        if (!routeCode) {
            throw new Error("Route code not provided");
        }

        let currentLangArgs = {
            lang: session.selectedLanguage.toLowerCase()
        };

        routeArgs = Object.assign({}, currentLangArgs, routeArgs);

        let routeInfo = router.routeInfoLookup[routeCode];
        if (!routeInfo) {
            throw new Error(`Route code ${routeCode} not found`);
        }

        routeInfo.params.forEach(p => {
            if (!routeArgs[p]) {
                throw new Error(`Route ${routeCode} expects ${p} argument, but this is not supplied`);
            }
        });

        let path = routeInfo.route.path;
        for (let routeParam in routeArgs) {
            path = path.replace(`:${routeParam}`, routeArgs[routeParam]);
        }

        return path;
    }

    static setRouteAttributes(routeCode, routeArgs) {
        if (!routeCode) {
            throw new Error("Need routeCode to be provided");
        }

        return `data-route="${routeCode}" data-route-args='${JSON.stringify(routeArgs || {})}'`;
    }

    static getScope(elem) {
        return elem.getAttribute("data-scope");
    }

    static setScope(elem, scope) {
        elem.setAttribute("data-scope", scope);
    }

    static noop() {}

    static getParentScope(elem) {
        do {
            elem = elem.parentElement;

            let scope = this.getScope(elem);
            if (scope) {
                return scope;
            }
        } while (elem);

        return null;
    }

    static getChildViews(containerElem, containerScope) {
        return Array
            .from(containerElem.querySelectorAll("[data-view]"))
            .filter(e => this.getParentScope(e) === containerScope)
            .map(e => ({
                element: e,
                viewCode: e.getAttribute("data-view"),
                configProperty: e.getAttribute("data-config")
            }));
    }

    static getChildRefValues(containerElem, containerScope) {
        return Array
            .from(containerElem.querySelectorAll("[ref]"))
            .filter(e => this.getParentScope(e) === containerScope)
            .map(e => ({
                element: e,
                property: e.getAttribute("ref")
            }));
    }

    static getMetadata(viewManagers, metadata) {
        viewManagers = viewManagers || document.viewManagers;
        metadata = metadata || {};
        viewManagers.forEach(viewManager => {
            if (viewManager.context.viewModel.metadata) {
                metadata = Object.assign(metadata, viewManager.context.viewModel.metadata);
            }

            this.getMetadata(viewManager.childViewManagers, metadata);
        });

        return metadata;
    }

    static removeOptionalMetaTags() {
        Array.from(document.querySelectorAll("meta[data-optional]")).forEach(tag => {
            tag.parentNode.removeChild(tag);
        });
    }

    static addOptionalMetaTag(session, property, content) {
        let tag = document.createElement("meta");
        tag.setAttribute("property", property);
        tag.setAttribute("data-optional", "");

        tag.content = typeof content === "object"
            ? this.getLocalizedValue(session, content, "value")
            : content;

        document.getElementsByTagName("head")[0].appendChild(tag);
    }

    static setHeadContentTags(session, metadata) {
        document.querySelector("title").textContent =
            this.getLocalizedValue(session, metadata, "title", false) || "";
        document.querySelector("meta[name='description']").content =
            this.getLocalizedValue(session, metadata, "description", false) || "";

        document.querySelector("meta[property='og:type']").content = metadata.type || "";
        document.querySelector("meta[property='og:title']").content =
            this.getLocalizedValue(session, metadata, "title", false) || "";
        document.querySelector("meta[property='og:description']").content =
            this.getLocalizedValue(session, metadata, "description", false) || "";
        document.querySelector("meta[property='og:image']").content = metadata.image || "";

        this.removeOptionalMetaTags();
        for (let property in (metadata.otherProperties || {})) {
            this.addOptionalMetaTag(session, property, metadata.otherProperties[property]);
        }
    }

    static setHeadLanguageTags(session) {
        let lang = session.selectedLanguage.toLowerCase();

        document.documentElement.lang = lang;

        document.querySelector("meta[http-equiv='content-language']").content = lang;
        document.querySelector("meta[property='og:locale']").content = this.getLocale(session);
    }

    static setHeadRouteTags(session, router) {
        let path = utils.getRoutePath(session, router, router.activeRouteCode, router.activeRouteArgs);
        document.querySelector("meta[property='og:url']").content = `${this.publicOrigin}${path}`;

        // Set the canonical link
        document.querySelector("link[rel='canonical']").href = `${this.publicOrigin}${path}`;

        // Set the hreflang links
        let enRouteArgs = Object.assign({}, router.activeRouteArgs, {lang: "en"});
        let thRouteArgs = Object.assign({}, router.activeRouteArgs, {lang: "th"});

        let enPath = utils.getRoutePath(session, router, router.activeRouteCode, enRouteArgs);
        let thPath = utils.getRoutePath(session, router, router.activeRouteCode, thRouteArgs);

        document.querySelector("link[hreflang='en']").href = `${this.publicOrigin}${enPath}`;
        document.querySelector("link[hreflang='th']").href = `${this.publicOrigin}${thPath}`;
    }

    static publishRouteAnalytics(session, router, metadata) {
        if (_googleAnalyticsCode === null) {
            throw new Error("Attempt to get googleAnalyticsCode property before setBrowserEnv has been called.");
        }

        let page = this.getLocalizedValue(session, metadata, "title", false) || "";
        let path = utils.getRoutePath(session, router, router.activeRouteCode, router.activeRouteArgs);

        if (_lastViewedPagePath !== path) {
            _lastViewedPagePath = path;
            window.ga("set", "page", path);
            window.ga("set", "title", page);
            window.ga("send", "pageview");
        }
    }

    static ensureScriptAdded(id, srcUrl) {
        let firstScript = document.getElementsByTagName("script")[0];
        if (!document.getElementById(id)) {
            let newScript = document.createElement("script");
            newScript.id = id;
            newScript.src = srcUrl;
            firstScript.parentNode.insertBefore(newScript, firstScript);
        }
    }

    static loadSocialPlugins(elem) {
        if (!this.isPrerendering) {
            // If Facebook API is loaded, parse the DOM to reconstruct any social plugins
            if (FB && FB.XFBML) {
                FB.XFBML.parse(elem);
            }

            if (LineIt && LineIt.loadButton) {
                LineIt.loadButton();
            }
        }
    }
}

function findParent(name, elem) {
    name = name.toLowerCase();
    let elemName = (elem.nodeName || elem.tagName).toLowerCase();
    if (elemName === name) {
        return elem;
    }

    while (elem = elem.parentNode) {  // eslint-disable-line no-cond-assign
        elemName = (elem.nodeName || elem.tagName).toLowerCase();
        if (elemName === name) {
            return elem;
        }
    }

    return null;
}