import {SearchResults} from "./search-results";

const DefaultMaxResultCount = 10;

export class SiteSearcher {
    constructor(firebaseClient) {
        this.firebaseClient = firebaseClient;

        // Product indexes
        this.tagProductIndex = {};
        this.productNameProductIndex = {};
        this.categoryNameProductIndex = {};

        // Other internal lookup stores
        this.productIdProductLookup = {};
        this.galleryImageIdGalleryImageLookup = {};
        this.productIdGalleryImageLookup = {};

        // Store a promise that we need to await before getting search results.
        // Fire and forget (inside constructor - code smell)
        this.indexPromise = this.buildIndexes().catch(log.error);
    }

    get priorityOrderedIndexes() {
        return [
            this.tagProductIndex,
            this.productNameProductIndex,
            this.categoryNameProductIndex
        ];
    }

    async buildIndexes() {
        let [categoryLookup, productLookup, galleryImageLookup] = await Promise.all([
            this.firebaseClient.getCategoryLookup(),
            this.firebaseClient.getProductLookup(),
            this.firebaseClient.getGalleryImageLookup()
        ]);

        for (let productId in productLookup) {
            let product = productLookup[productId];

            addWordToIndex(this.productNameProductIndex, product.name_EN, productId);
            addWordToIndex(this.productNameProductIndex, product.name_TH, productId);

            if (product.categoryId) {
                let category = categoryLookup[product.categoryId];
                if (category) {
                    addWordToIndex(this.categoryNameProductIndex, category.name_EN, productId);
                    addWordToIndex(this.categoryNameProductIndex, category.name_TH, productId);
                }
            }

            if (product.tags) {
                for (let tag in product.tags) {
                    addWordToIndex(this.tagProductIndex, tag, productId);
                }
            }
        }

        // Store the other lookups
        this.productIdProductLookup = productLookup;
        for (let productId in this.productIdProductLookup) {
            let product = this.productIdProductLookup[productId];
            product.id = productId;
            product.category = product.categoryId ? categoryLookup[product.categoryId] : null;
            product.tagArray = Object.keys(product.tags || {});
        }

        this.galleryImageIdGalleryImageLookup = galleryImageLookup;
        for (let galleryImageId in galleryImageLookup) {
            let galleryImage = galleryImageLookup[galleryImageId];
            let productIds = galleryImage.productIds || {};
            for (let productId in productIds) {
                let galleryImageLookup =
                    this.productIdGalleryImageLookup[productId] ||
                    (this.productIdGalleryImageLookup[productId] = {});

                galleryImageLookup[galleryImageId] = galleryImage;
            }
        }
    }

    async searchProducts(text, maxResultCount = DefaultMaxResultCount) {
        // Ensure indexing has finished
        await this.indexPromise;

        // Normalize text before searching
        text = normalize(text);

        // Trim and split text
        let words = text
            .split(" ")
            .map(w => w.trim())
            .filter(w => !!w);

        let searchResults = new SearchResults();

        // Add exact matches from all indexes first.
        for (let index of this.priorityOrderedIndexes) {
            // Separate these search results from any higher-priority ones.
            searchResults.addResultGroup();

            for (let word of words) {
                addMatchesToSearchResults(index, searchResults, word, true);
            }
        }

        // If we still don't have enough results, search by substring
        if (searchResults.getCount() < maxResultCount) {
            for (let index of this.priorityOrderedIndexes) {
                searchResults.addResultGroup();

                for (let word of words) {
                    addMatchesToSearchResults(index, searchResults, word, false);
                }
            }
        }

        return searchResults.getSortedEntities(
            id => this.productIdProductLookup[id],
            (a, b) => a.time - b.time,
            maxResultCount);
    }

    async getGalleryImages(productIds, maxResultCount = DefaultMaxResultCount) {
        // Ensure indexing has finished
        await this.indexPromise;

        let galleryImageIds = [];

        for (let productId of productIds) {
            let galleryImageLookup = this.productIdGalleryImageLookup[productId] || {};
            for (let galleryImageId in galleryImageLookup) {
                if (galleryImageIds.indexOf(galleryImageId) < 0) {
                    galleryImageIds.push(galleryImageId);
                }
            }
        }

        let allGalleryImages = galleryImageIds
            .map(id => Object.assign({id: id}, this.galleryImageIdGalleryImageLookup[id]))
            .sort((a, b) => a.time - b.time);

        let results = [];
        for (let galleryImage of allGalleryImages) {
            if (results.length >= maxResultCount) {
                return results;
            }

            results.push(galleryImage);
        }

        return results;
    }
}

function normalize(text) {
    // As text may be in Thai, it's prudent to normalize it first, so that
    // graphemes which can have multiple code-point representations (e.g. of
    // combining marks) are reduced to their canonical representation for
    // comparison (e.g. with tags).
    // However... a native String.normalize function isn't available on every
    // browser, and polyfills are enormous. So, if that function doesn't exist,
    // we just skip that.
    if ("".normalize) {
        text = text.normalize();
    }

    // We also convert to lowercase for canonical representations of latin
    // characters.
    return text.toLowerCase();
}

function addWordToIndex(index, word, entityId) {
    if (!word) {
        // Can't index undefined, null or empty string
        return;
    }

    word = normalize(word);

    // This word is associated with a collection of entityIds.
    // Store these as a javascript object (hash lookup) to efficiently avoid
    // adding duplicate entries.
    let entityIdLookup = index[word] || (index[word] = {});
    entityIdLookup[entityId] = true;
}

function addMatchesToSearchResults(index, searchResults, word, exactOnly = true) {
    let wordResultIds;
    if (exactOnly) {
        wordResultIds = index[word] || {};
    } else {
        wordResultIds = {};
        for (let indexedWord in index) {
            if (indexedWord.substr(0, word.length) === word) {
                Object.assign(wordResultIds, index[indexedWord]);
            }
        }
    }

    for (let resultId in wordResultIds) {
        searchResults.addMatch(resultId);
    }
}