export class SearchBarResults {
    constructor(parentContext) {
        this.searchBarViewModel = parentContext.viewModel;
        this.products = [];
        this.galleryImages = [];

        // From ref attribute
        this.listElem = null;

        this.state = {
            documentKeyDownListener: null
        };
    }

    activate() {
        this.searchBarViewModel.setResultChangeListener((products, galleryImages) => {
            this.products = products;
            this.galleryImages = galleryImages;
        });
    }

    rendered() {
        // listElem is not always rendered
        if (this.listElem) {
            this.listElem.onclick = () => this.clear();
        }

        this.state.documentKeyDownListener = e => this.handleDocumentKeyDown(e || window.event);
        document.addEventListener("keydown", this.state.documentKeyDownListener);
    }

    handleDocumentKeyDown(e) {
        let isEscape = "key" in e
            ? (e.key === "Escape") || (e.key === "Esc")
            : e.keyCode === 27;

        if (isEscape) {
            this.clear();
        }
    }

    clear() {
        if (this.products.length || this.galleryImages.length) {
            this.products = [];
            this.galleryImages = [];
        }
    }

    dispose() {
        if (this.state.documentKeyDownListener) {
            document.removeEventListener("keydown", this.state.documentKeyDownListener);
        }
    }
}