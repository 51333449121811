// Individual polyfill replacements for babel-polyfill
import "core-js/es6/array";
import "core-js/es6/object";
import "core-js/es6/promise";
import "core-js/es6/symbol";
import "regenerator-runtime/runtime";

// Missing from core-js ( https://github.com/zloirock/core-js/blob/master/README.md#missing-polyfills )
import "proxy-polyfill/proxy.min";
import "whatwg-fetch";

// Initialize any global variables that need to be available for the lifetime
// of the page.
import "./infrastructure/global-init";

// Views need to be imported *after* global-init, which ensures the "views"
// namespace is set up.
import "./views/components";
import "./views/pages";

import {config} from "./config";

import {CachingRestClient} from "./infrastructure/caching-rest-client";
import {FirebaseClient} from "./infrastructure/firebase-client";
import {LangaugeChangeResponder} from "./infrastructure/language-change-responder";
import {MetadataChangeResponder} from "./infrastructure/metadata-change-responder";
import {RestClient} from "./infrastructure/rest-client";
import {RouteChangeResponder} from "./infrastructure/route-change-responder";
import {Router} from "./infrastructure/router";
import {Session} from "./infrastructure/session";
import {SiteSearcher} from "./infrastructure/site-searcher";
import {ViewManager} from "./infrastructure/view-manager";
import {ViewInitializer} from "./infrastructure/view-initializer";

import {Album} from "./components/album";
import {App} from "./components/app";
import {Carousel} from "./components/carousel";
import {ColorDisplay} from "./components/color-display";
import {ImageAlbum} from "./components/image-album";
import {LanguageSelector} from "./components/language-selector";
import {Lightbox} from "./components/lightbox";
import {NavBar} from "./components/nav-bar";
import {PageContainer} from "./components/page-container";
import {ProductCards} from "./components/product-cards";
import {SearchBar} from "./components/search-bar";
import {SearchBarResults} from "./components/search-bar-results";
import {SiteHeader} from "./components/site-header";

import {Home} from "./pages/home";
import {AboutUs} from "./pages/about-us";
import {ContactUs} from "./pages/contact-us";
import {ListProducts} from "./pages/list-products";
import {Gallery} from "./pages/gallery";
import {PageNotFound} from "./pages/page-not-found";
import {ShowProduct} from "./pages/show-product";
import {ShowImage} from "./pages/show-image";

utils.setBrowserEnv(config.prerenderOrigins, config.publicOrigin, config.googleAnalyticsCode);

// https://www.npmjs.com/package/loglevel
log.setLevel(config.logLevel || "warn");

// Enable livereload for localhost only, and not when prerendering
if (utils.isLocalhost && !utils.isPrerendering) {
    document.write("<script src=\"http://localhost:35729/livereload.js?snipver=1\"></script>");
}

// We're trying to avoid creating Facebook, etc. SDK content when prerendering. Ensure this
// by only loading external scripts when we're not prerendering.
if (!utils.isPrerendering) {
    utils.ensureScriptAdded("facebook-jssdk", "https://connect.facebook.net/en_US/sdk.js#xfbml=1&version=v2.8&appId=406866739696254");
    utils.ensureScriptAdded("line-loader", "https://d.line-scdn.net/r/web/social-plugin/js/thirdparty/loader.min.js");
    utils.ensureScriptAdded("google-maps", "https://maps.googleapis.com/maps/api/js?key=AIzaSyBp-Zt6yB3-0Ht9x9HqXlkYopNvb9K_ovI&callback=mapsApiReady");
}

let firebaseRestClient = new RestClient(config.firebaseDbUrl);
let cachingRestClient = new CachingRestClient(firebaseRestClient, config.cacheMinutes);
let firebaseClient = new FirebaseClient(cachingRestClient, config.activeProject);
let siteSearcher = new SiteSearcher(firebaseClient);
let session = new Session();

let routeParams = {
    "lang": /(en|th)/,
    "anything": /((\w+|\/)+)/
};

let baseHref = utils.getBaseHref();
let routeLookup = {
    "home": {
        provider: () => new Home(firebaseClient, session, config.businessInfo),
        renderer: views.pages["home"],
        route: {path: `${baseHref}:lang`, title_EN: "Home", title_TH: "หน้าแรก"}
    },
    "about-us": {
        provider: () => new AboutUs(),
        renderer: views.pages["about-us"],
        route: {path: `${baseHref}:lang/about-us`, title_EN: "About Us", title_TH: "เกี่ยวกับเรา"}
    },
    "contact-us": {
        provider: () => new ContactUs(config.businessInfo),
        renderer: views.pages["contact-us"],
        route: {path: `${baseHref}:lang/contact-us`, title_EN: "Contact Us", title_TH: "ติดต่อเรา"}
    },
    "list-products": {
        provider: () => new ListProducts(firebaseClient),
        renderer: views.pages["list-products"],
        route: {path: `${baseHref}:lang/product`, title_EN: "Our Products", title_TH: "สินค้า"}
    },
    "show-product": {
        provider: () => new ShowProduct(firebaseClient, siteSearcher),
        renderer: views.pages["show-product"],
        route: {path: `${baseHref}:lang/product/:code`, title_EN: "Product", title_TH: "สินค้า"}
    },
    "gallery": {
        provider: () => new Gallery(firebaseClient, session),
        renderer: views.pages["gallery"],
        route: {path: `${baseHref}:lang/gallery`, title_EN: "Gallery", title_TH: "ตัวอย่างผลงาน"}
    },
    "show-image": {
        provider: () => new ShowImage(firebaseClient, session),
        renderer: views.pages["show-image"],
        route: {path: `${baseHref}:lang/gallery/:code`, title_EN: "Image", title_TH: "รูปภาพ"}
    },
    "lang-page-not-found": {
        provider: () => new PageNotFound(siteSearcher),
        renderer: views.pages["page-not-found"],
        route: {path: `${baseHref}:lang/:anything`, title_EN: "Not Found", title_TH: "ผักชนิดหนึ่ง"}
    },
    "page-not-found": {
        provider: () => new PageNotFound(siteSearcher),
        renderer: views.pages["page-not-found"],
        route: {path: `${baseHref}:anything`, title_EN: "Not Found", title_TH: "ผักชนิดหนึ่ง"}
    }
};

let routes = Object.keys(routeLookup).map(v => Object.assign({code: v}, routeLookup[v].route));
let router = new Router(routes, routeParams);

let componentProviderLookup = {
    "album": () => new Album(),
    "app": () => new App(),
    "carousel": () => new Carousel(),
    "color-display": () => new ColorDisplay(),
    "image-album": () => new ImageAlbum(firebaseClient),
    "language-selector": () => new LanguageSelector(session, router),
    "lightbox": () => new Lightbox(),
    "nav-bar": () => new NavBar(session, router, config.appTitle_EN, config.appTitle_TH),
    "page-container": () => new PageContainer(router),
    "product-cards": () => new ProductCards(firebaseClient),
    "search-bar": () => new SearchBar(session, siteSearcher),
    "search-bar-results": context => new SearchBarResults(context),
    "site-header": () => new SiteHeader()
};

let viewModelProviderLookup = {};
Object.keys(routeLookup).forEach(code => viewModelProviderLookup[code] = routeLookup[code].provider);
Object.keys(componentProviderLookup).forEach(code => viewModelProviderLookup[code] = componentProviderLookup[code]);

let rendererLookup = {};
Object.keys(routeLookup).forEach(code => rendererLookup[code] = routeLookup[code].renderer);
Object.keys(componentProviderLookup).forEach(code => rendererLookup[code] = views.components[code]);

let viewInitializer = new ViewInitializer();

// Wrap async code in a (non-arrow) function.
// If we don't do this, the build process complains of a top-level "this" reference
// which doesn't make sense (is undefined) in a top-level ES6 module.
(function () {
    // http://stackoverflow.com/questions/9899372/pure-javascript-equivalent-to-jquerys-ready-how-to-call-a-function-when-the/9899701#9899701
    window.documentReady = async () => {
        // Initialize the router to get the active route information. This should ensure
        // the active route data is set on the router, which may be used when building the
        // view managers and views.
        router.initialize();

        // Nothing consumes these, but we need to keep them globally:
        document.viewManagers = await ViewManager.createForDocument(viewModelProviderLookup, rendererLookup, session, router, viewInitializer);
        document.languageChangeResponder = new LangaugeChangeResponder(session, router);
        document.metadataChangeResponder = new MetadataChangeResponder(session, router);
        document.routeChangeResponder = new RouteChangeResponder(session, router);

        // The view manager tree may have registered subscriptions to route and language
        // change events.
        // Publish these events one time to make sure views are correctly initialized, 
        // but ONLY if the document isn't pre-rendered.
        if (document.viewManagers.some(vm => !vm.context.keepExistingStructure)) {
            pubsub.publish("route-change");
            pubsub.publish("language-change");
            pubsub.publish("metadata-change");
        } else {
            // If we're keeping the existing structure, make sure that it's a one-time thing.
            document.viewManagers.forEach(vm => vm.allowStructureChange());
        }

        // Because we're using pushState, we need to intercept all <a> click events
        // and send them to the router.
        utils.handleClicks((routeCode, routeArgs) => {
            let path = utils.getRoutePath(session, router, routeCode, routeArgs);
            router.setPath(path);
        });

        utils.preventImageContextMenu();
    };
})();


