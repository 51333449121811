export class Gallery {
    constructor(firebaseClient) {
        this.firebaseClient = firebaseClient;
    }

    get metadata() {
        return {
            type: "article",
            title_EN: "Gallery",
            title_TH: "ตัวอย่างผลงาน",
            description_EN: "A showcase of Fabric Buttons products",
            description_TH: "ตัวอย่างผลงานสั่งทำจากลูกค้ารูปแบบต่างๆ",
            image: `${utils.publicOrigin}/image/fb-facebook-image.jpg`
        };
    }
}