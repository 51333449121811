export class AboutUs {

    get metadata() {
        return {
            type: "article",
            title_EN: "About Us",
            title_TH: "เกี่ยวกับเรา",
            description_EN: "Discover what Fabric Buttons does, and how we can help you",
            description_TH: "สินค้าและบริการของ Fabric Buttons (ร้านรุ่งมณี)",
            image: `${utils.publicOrigin}/image/fb-facebook-image.jpg`
        };
    }
}