
if (typeof(views.components) === "undefined") {
    views.components = () => new Function();
}
    views.components["album"] = function(it) {
var out='<ul class="album" ref="listElem"> ';var arr1=it.images;if(arr1){var image,i1=-1,l1=arr1.length-1;while(i1<l1){image=arr1[i1+=1];out+=' <li data-image-id="'+(image.id)+'"> <a '+(utils.setRouteAttributes(image.routeCode, image.routeArgs))+' class="block-anchor"> <div class="img-cropper '+(!image.hasWatermark ? 'watermark' : '')+'"> <img src="'+(image.thumbnailUrl)+'" data-alt-en="'+(image.alt_EN)+'" data-alt-th="'+(image.alt_TH)+'"> </div> <div class="text-cover"> ';var arr2=image.textLines_EN;if(arr2){var textLine,i2=-1,l2=arr2.length-1;while(i2<l2){textLine=arr2[i2+=1];out+=' <p lang="en">'+(textLine)+'</p> ';} } out+=' ';var arr3=image.textLines_TH;if(arr3){var textLine,i3=-1,l3=arr3.length-1;while(i3<l3){textLine=arr3[i3+=1];out+=' <p lang="th">'+(textLine)+'</p> ';} } out+=' </div> </a> </li> ';} } out+='</ul>';return out;
};
views.components["app"] = function(it) {
var out='<div class="page-wrapper"> <div data-view="nav-bar"></div> <div data-view="site-header"></div> <div class="content-container outer-container" data-view="page-container"></div></div><footer class="footer"> <div class="outer-container"> <ul> <li>&copy; 2016–'+(it.currentYear)+' Fabric Buttons. All rights reserved.</li> </ul> </div></footer>';return out;
};
views.components["carousel"] = function(it) {
var out='<div class="carousel-container" ref="containerElem"> <ol ref="listElem"> ';var arr1=it.items;if(arr1){var item,i1=-1,l1=arr1.length-1;while(i1<l1){item=arr1[i1+=1];out+=' <li class="hover-tile-container '+(!item.hasWatermark ? 'watermark' : '')+'" style="width: '+(it.imageHeight)+'px; height: '+(it.imageHeight)+'px"> <img src="'+(item.imageUrl)+'" data-alt-en="'+(item.name_EN)+'" data-alt-th="'+(item.name_TH)+'"> <a '+(utils.setRouteAttributes(item.routeCode, item.routeArgs))+' class="hover-tile block-anchor"> <h3> <span lang="en">'+(item.name_EN || "")+'</span> <span lang="th">'+(item.name_TH || "")+'</span> </h3> ';var arr2=item.descriptionLines_EN;if(arr2){var descriptionLine,i2=-1,l2=arr2.length-1;while(i2<l2){descriptionLine=arr2[i2+=1];out+=' <p lang="en">'+(descriptionLine)+'</p> ';} } out+=' ';var arr3=item.descriptionLines_TH;if(arr3){var descriptionLine,i3=-1,l3=arr3.length-1;while(i3<l3){descriptionLine=arr3[i3+=1];out+=' <p lang="th">'+(descriptionLine)+'</p> ';} } out+=' <p> <span lang="en">'+(item.description_EN || "")+'</span> <span lang="th">'+(item.description_TH || "")+'</span> </p> </a> </li> ';} } out+=' </ol>  <div class="nav-buttons"> <button class="prev" ref="prevButton"> <span lang="en">Previous</span> <span lang="th">ก่อนหน้า</span> </button> <button class="next" ref="nextButton"> <span lang="en">Next</span> <span lang="th">ถัดไป</span> </button> </div></div>';return out;
};
views.components["color-display"] = function(it) {
var out='<div class="color-display"> <span class="primary">Primary</span> <span class="secondary">Secondary</span> <span class="foreground">Foreground</span> <span class="background">Background</span> <span class="accent">Accent</span></div>';return out;
};
views.components["image-album"] = function(it) {
var out='<div data-view="album" data-config="albumConfig"></div><div data-view="lightbox" data-config="lightboxConfig"></div><div ref="lightboxContentElem" class="image-album-lightbox-content"> ';var arr1=it.images;if(arr1){var image,i1=-1,l1=arr1.length-1;while(i1<l1){image=arr1[i1+=1];out+=' <img src="'+(utils.getImageUrl(image, 450))+'" data-img="'+(image.id)+'" data-alt-en="'+(image.description_EN)+'" data-alt-th="'+(image.description_TH)+'"> <a data-image-id="'+(image.id)+'" '+(utils.setRouteAttributes("show-image", {code: image.id}))+' class="block-anchor"> <h3> <span lang="en">'+(image.description_EN || "")+'</span> <span lang="th">'+(image.description_TH || "")+'</span> </h3> </a> <dl data-image-id="'+(image.id)+'"> ';if(image.products.length){out+=' <dt> <span lang="en">Products</span> <span lang="th">สินค้า</span> </dt> <dd> <ul> ';var arr2=image.products;if(arr2){var product,i2=-1,l2=arr2.length-1;while(i2<l2){product=arr2[i2+=1];out+=' <li> <a '+(utils.setRouteAttributes("show-product", {code: product.id}))+'> <span lang="en">'+(product.name_EN)+'</span> <span lang="th">'+(product.name_TH)+'</span> </a> </li> ';} } out+=' </ul> </dd> ';}out+=' </dl> ';} } out+='</div>';return out;
};
views.components["language-selector"] = function(it) {
var out='<div class="language-selector" ref="containerElem"> ';var arr1=it.languages;if(arr1){var language,index=-1,l1=arr1.length-1;while(index<l1){language=arr1[index+=1];out+=' ';if(index > 0){out+='|';}out+=' <a data-language="'+(language.value)+'">'+(language.text)+'</a> ';} } out+='</div>';return out;
};
views.components["lightbox"] = function(it) {
var out='<div class="lightbox" ref="lightboxElem"> <button class="lightbox-close" ref="closeButton"> ✖ <span lang="en">Close</span> <span lang="th">ปิด</span> </button> <div class="image-container" ref="imgContainer"> </div> <div class="nav-and-content"> <button class="lightbox-prev" ref="prevButton"> <span lang="en">Previous</span> <span lang="th">ก่อนหน้า</span> </button> <button class="lightbox-next" ref="nextButton"> <span lang="en">Next</span> <span lang="th">ถัดไป</span> </button> <div class="content-container" ref="contentContainer"> </div> </div></div>';return out;
};
views.components["nav-bar"] = function(it) {
var out='<header class="navigation" role="banner"> <div class="outer-container"> <a href="#" class="navigation-menu-button" ref="menuToggle"> &#9776; <span lang="en">MENU</span> <span lang="th">เมนู</span> </a> <nav role="navigation"> <ul ref="navigationMenu" class="navigation-menu small-screen-hidden"> <li class="nav-link" data-route-code="home"> <a '+(utils.setRouteAttributes("home"))+' class="home-link block-anchor"> <span lang="en">'+(it.appTitle_EN)+'</span> <span lang="th">'+(it.appTitle_TH)+'</span> </a> </li> ';var arr1=it.navigableRoutes;if(arr1){var route,index=-1,l1=arr1.length-1;while(index<l1){route=arr1[index+=1];out+=' <li class="nav-link" data-route-code="'+(route.code)+'"> <a '+(utils.setRouteAttributes(route.code))+'> <span lang="en">'+(route.title_EN)+'</span> <span lang="th">'+(route.title_TH)+'</span> </a> </li> ';} } out+='<!--                 <li id="js-navigation-more" class="nav-link more"> <a href="#"> <span lang="en">More</span> <span lang="th">aaaaaaaaaaaaaaaa</span> </a> <ul class="submenu"> <li><a ref="colorDisplayToggle" href="#" ref="">Color Display</a></li> <li><a href="#">Another Item</a></li> <li class="more"> <a href="#">Item with submenu</a> <ul class="submenu"> <li><a href="#">Sub-submenu Item</a></li> <li><a href="#">Another Item</a></li> </ul> </li> <li class="more"> <a href="#">Another submenu</a> <ul class="submenu"> <li><a href="#">Sub-submenu</a></li> <li><a href="#">An Item</a></li> </ul> </li> </ul> </li> --> </ul> </nav> <div class="navigation-tools"> <div class="search-bar-container" data-view="search-bar"></div> <div class="language-selector-container" data-view="language-selector"></div> </div> </div></header><div ref="colorDisplay" data-view="color-display" class="hidden"></div>';return out;
};
views.components["page-container"] = function(it) {
var out='';if(it.routeCode){out+='<div class="page-container" data-view="'+(it.routeCode)+'"></div>';}return out;
};
views.components["product-cards"] = function(it) {
var out='<div class="product-cards"> ';var arr1=it.products;if(arr1){var product,i1=-1,l1=arr1.length-1;while(i1<l1){product=arr1[i1+=1];out+=' <div class="card"> <a '+(utils.setRouteAttributes("show-product", {code: product.id}))+' class="block-anchor"> <div class="card-image '+(!product.hasWatermark ? 'watermark' : '')+'"> <img src="'+(utils.getImageUrl(product, 150))+'" data-alt-en="'+(product.name_EN)+'" data-alt-th="'+(product.name_TH)+'"> </div> <div class="card-header"> <span lang="en">'+(product.name_EN)+'</span> <span lang="th">'+(product.name_TH)+'</span> </div> </a> <div class="card-copy"> ';var arr2=product.descriptionLines_EN;if(arr2){var descriptionLine,i2=-1,l2=arr2.length-1;while(i2<l2){descriptionLine=arr2[i2+=1];out+=' <p lang="en">'+(descriptionLine)+'</p> ';} } out+=' ';var arr3=product.descriptionLines_TH;if(arr3){var descriptionLine,i3=-1,l3=arr3.length-1;while(i3<l3){descriptionLine=arr3[i3+=1];out+=' <p lang="th">'+(descriptionLine)+'</p> ';} } out+=' <dl> ';if(product.category){out+=' <dt> <span lang="en">Category</span> <span lang="th">หมวดหมู่</span> </dt> <dd> <span lang="en">'+(product.category.name_EN)+'</span> <span lang="th">'+(product.category.name_TH)+'</span> </dd> ';}out+=' ';if(product.price){out+=' <dt> <span lang="en">Price</span> <span lang="th">ราคา</span> </dt> <dd> <span lang="en">'+(product.price_EN)+'</span> <span lang="th">'+(product.price_TH)+'</span> </dd> ';}out+=' </dl> </div> </div> ';} } out+='</div>';return out;
};
views.components["search-bar-results"] = function(it) {
var out='';if(it.products.length || it.galleryImages.length){out+='<ul class="search-bar-results" ref="listElem"> <span class="search-results-close-button"></span> ';var arr1=it.products;if(arr1){var product,i1=-1,l1=arr1.length-1;while(i1<l1){product=arr1[i1+=1];out+=' <li> <a '+(utils.setRouteAttributes("show-product", {code: product.id}))+' class="block-anchor"> <div class="img-cropper"> <img src="'+(utils.getImageUrl(product, 150))+'" data-alt-en="'+(product.name_EN)+'" data-alt-th="'+(product.name_TH)+'"> </div> </a> <a '+(utils.setRouteAttributes("show-product", {code: product.id}))+' class="block-anchor"> <dl> <dt> <span lang="en">'+(product.name_EN || "")+'</span> <span lang="th">'+(product.name_TH || "")+'</span> </dt> <dd> <span lang="en">'+(product.description_EN || "")+'</span> <span lang="th">'+(product.description_TH || "")+'</span> </dd> ';if(product.category){out+=' <dt> <span lang="en">Category</span> <span lang="th">หมวดหมู่</span> </dt> <dd> <span lang="en">'+(product.category.name_EN)+'</span> <span lang="th">'+(product.category.name_TH)+'</span> </dd> ';}out+=' </dl> </a> </li> ';} } out+='</ul>';}return out;
};
views.components["search-bar"] = function(it) {
var out='<form class="search-bar" role="search"> <input type="search" ref="inputElem" /> <button ref="searchButtonElem" type="submit" class="icon icon-search"> <span class="offscreen"> <span lang="en">Search</span> <span lang="th">ค้นหา</span> </span> </button> <div class="search-bar-results-container" data-view="search-bar-results"></div></form>';return out;
};
views.components["site-header"] = function(it) {
var out='<header class="site-header" role="banner"> <div class="header-content-container"> <div class="header-content"> <span class="heading-text">Fabric Buttons | รุ่งมณี</span> <p> <span> বোতাম | Bottone | 단추 | Kancing | زرار | 捺扣 | دکمه | Knoop </span> </p> </div> </article></header>';return out;
};
