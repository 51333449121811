import director from "director";

export class Router {
    constructor(routes, routeParams) {
        this._router = new director.Router()
            .configure({
                html5history: true,
                notfound: () => {
                    log.warn("Route not found");
                }
            });

        this.routeInfoLookup = {};
        this.activeRouteCode = null;
        this.activeRouteArgs = {};
        this.isInitialized = false;

        if (routes) {
            this.addRoutes(routes, routeParams || {});
        }
    }

    addRoutes(routes, routeParams) {
        for (let paramName in routeParams) {
            this._router.param(paramName, routeParams[paramName]);
        }

        for (let route of routes) {
            if (!route.code || typeof route.code !== "string") {
                throw new Error("code must be a string");
            }

            if (!route.path || typeof route.path !== "string") {
                throw new Error("path must be a string");
            }

            this._router.on(route.path, this.buildRouteHandler(route.code));

            this.routeInfoLookup[route.code] = {
                route: route,
                params: route.path
                    .split("/")
                    .filter(p => p && p[0] === ":")
                    .map(p => p.slice(1)) // extract params (e.g. 'lang' from /:lang/a/b/c)
            };
        }
    }

    setPath(path) {
        this._router.setRoute(path);
    }

    initialize() {
        // This invokes the current route handler, which should set up the active
        // route data. At this stage, it should not publish a route-change event,
        // because the page MAY be prerendered, and if it is, we don't want to redraw
        // it.
        this._router.init();
        this.isInitialized = true;
    }

    buildRouteHandler(routeCode) {
        // The director.js route handler is a function that takes a variable
        // number of arguments (depending on the specified path)
        return (...argArray) => {
            this.activeRouteCode = routeCode;
            let routeInfo = this.routeInfoLookup[routeCode];

            // The number of arguments extracted from the URL needs to be *at least*
            // as long as the expected number of params. It may be more, in which
            // case the excess args are discarded.
            if (argArray.length < routeInfo.params.length) {
                throw new Error(`Arguments ${JSON.stringify(argArray)} length less than match params ${JSON.stringify(routeInfo.params)} length.`);
            }

            let activeRouteArgs = {};
            routeInfo.params.forEach((param, index) => {
                activeRouteArgs[param] = argArray[index];
            });

            this.activeRouteArgs = activeRouteArgs;

            if (this.isInitialized) {
                // Only publish a route change if this is NOT initial setup.
                pubsub.publish("route-change", routeCode, activeRouteArgs);
            }
        };
    }
}