export class Album {
    constructor() {
        this.images = [];

        // From ref attribute
        this.listElem = null;

        this.state = {
            clickHandler: null
        };
    }

    configure(config) {
        this.images = config.images;
        this.state.clickHandler = config.clickHandler;
    }

    rendered() {
        if (this.state.clickHandler) {
            let itemElems = Array.from(this.listElem.querySelectorAll("li[data-image-id]"));
            itemElems.forEach(itemElem => {
                utils.handleClick(itemElem, () => {
                    let imageId = itemElem.getAttribute("data-image-id");
                    this.state.clickHandler(imageId);
                });
            });
        }
    }
}